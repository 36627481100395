@font-face {
  font-family: "Frutiger";
  src: url("3fbbd6b1-cfa7-4ff0-97ea-af1b2c489f15.woff") format("woff"); /* FrutigerLTW01-55Roman */
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next LT Pro";
  src: url("AvenirNextLTPro-Regular.woff") format("woff"); /* Avenir Next LT Pro Regular */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next LT Pro";
  src: url("AvenirNextLTPro-Bold.woff") format("woff"); /* Avenir Next LT Pro Bold */
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
